.genie-chat-container {
    width: 200px;
    height: 500px;
    background-color: transparent;
    z-index: 10;
    position: absolute;
    top: 10rem;
    right: 6rem;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    z-index: 14;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
}

.button {
    padding: 0.5rem 1rem;
    background-color: red;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.send-button {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: red;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    
}

.message-list {
    background-color: transparent !important;
    margin-top: .5rem;
    
    /* opacity: 0; */
}

.cs-main-container, .main-container {
background-color: rgba(178, 141, 255, .2) !important;
    border-radius: 1rem;
    /* padding-top: .5rem; */
    border-radius: 10px 10px 0px 0px;
    border: 2px solid #ABC4FF;
    border-bottom: none;
    
}

.cs-message__content {
    background-color: #B28DFF !important;
    font-family: oatmeal-pro-regular !important;
}

.cs-message--outgoing .cs-message__content {
    background-color: #ABC4FF !important;
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem;
}

.chat-container {
    background-color: rgba(178, 141, 255, .2) !important;
    /* background-color: red !important; */
    /* background-color: rgba(255, 255, 255, .3) !important; */
    /* opacity: .4; */
    
}

.cs-typing-indicator {
    background-color: transparent !important;
}

.cs-message-input {
    background-color: transparent;
    color: black !important;
    /* border: 1px solid white; */
    /* border-radius: 0; */
    /* border-top-right-radius: none !important; */
}

.cs-message-input__content-editor-wrapper{
    /* border-top-right-radius: none !important; */
    /* border-top-left-radius: .7rem; */
    /* border-top-right-radius: none !important; */
    border-radius: 0px 0px 10px 10px;
    background-color: #B28DFF;
    border: 2px solid #ABC4FF;
    border-top: none;
    
}

.cs-message-input__content-editor{
        background-color: #B28DFF;
       
}

 .ps__rail-y {
    background-color: transparent !important;
}

.ps__thumb-y {
    background-color: #ABC4FF;
}



.apply-image-btn {
    /* height: 40px; */
    /* min-width: 140px; */
    /* width: 13vw; */
    margin-top: 1rem;
    font-family: oatmeal-pro-bold;
    padding: .7rem;
    /* background-color: rgba(178, 141, 255, .7) !important; */
    font-size: 15px;
    border: 1px solid white;
    border-radius: .5rem;
    color: black;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.apply-image-btn:hover {
    /* height: 40px; */
    transform: scale(1.1);
    /* background-color: white !important; */
   
}


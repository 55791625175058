.clickable {
    cursor: pointer;
    z-index: 10;
}

.dropdown {
    border: 1px solid black;
    width: 200px;
    position: fixed;
    z-index: 30;
    /* z-index: 18; */
    background-color: rgba(255, 255, 255, .3);

    max-height: 200px;
    overflow-y: auto;
    border-radius: 1rem;
    font-family: oatmeal-pro-regular;
    border: 2px solid #5300FF;
    box-sizing: border-box;
    backdrop-filter: blur(10px);
        /* Adjust the pixel value to control the amount of blur */
        -webkit-backdrop-filter: blur(10px);
        /* For Safari compatibility */
    /* position: fixed; */
}

.dropdown div {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    
}



.dropdown div:hover {
    background-color: #f5f5f5;

}





.preview-image {
    width: 100%;
    /* min-height: 400px; */
    

    /* background: transparent; */
    
    /* height: 100%; */
    /* width: 100px; */
    /* background-color: red; */
}



.search-input {
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    border: none;
    height: 50px;
    font-size: 20px;
    font-family: oatmeal-pro-regular;
    padding-left: 1rem; 
    border-radius: 0 !important;
    background-color: rgba(255, 255, 255, .3);
}

.dropdown input:focus {
    outline: none;
}

.dropdown-items.hidden {
    display: none;
}

.prompt-content-container {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    /* background-color: yellow; */
    overflow-y: auto;
    /* Makes vertical overflow scrollable */
    max-height: 81.5vh;
    max-width: 100%;
    width: 90%;
    height: 500px;
    /* margin-top: 30vh; */
   
    
    /* Set this to your desired maximum height */
}

.prompt-content-container > h1 {
    font-size: 36px;
    margin-top: .5rem;
}

.prompt-content-container > div > p {
    margin: 0;
    opacity: .6;
    font-family: oatmeal-pro-regular;
    display: flex;
    width: 100%;
}


.preview-images-container {
    /* display: grid; */
    /* grid-template-columns: repeat(2, 1fr); */
    /* grid-template-rows: repeat(2, 1fr); */
    gap: 1rem;
    /* background-color: red; */
    width: 380px;
    height: 380px;
    /* Adjust this for space between the squares */
}


.preview-image-container {
    /* box-sizing: border-box; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 380px;
    height: 380px;
    /* min-width: 380px; */
    /* height: 100%; */
    /* padding-bottom: 95%; */
    /* This creates the aspect ratio for the container */
    background-color: rgba(255, 255, 255, .3);;
    border-radius: 1rem;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    position: relative;
    /* This is necessary for the absolute positioning of the img inside */
}

.preview-image-container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* This ensures that images are resized to cover the container */
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 1rem;
    /* min-height: 405px !important; */
}

.prompt-buttons-container{
    display: flex;
    /* justify-content: left; */
    align-items: start;
    /* background-color: red; */
    width: 93%;
    gap: 1vw;
    justify-content: center;
    justify-content: space-between;
    border-radius: 1rem !important;
    /* gap: 2rem; */
    /* margin-top: .5rem; */
    /* flex-direction: column; */
}

.prompt-buttons-container button {
    /* margin: 1rem; */
    width: 11vw;
    /* box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3); */
}

.prompt-buttons-container>button:first-child {
    width:10vw !important;
    padding: .5rem !important;
    padding-top: .5rem !important;
        padding-bottom: .5rem !important;
    background-color: #2685e3;
    border: none;
    color: black;

}

.prompt-buttons-container>button:last-child, a {
    /* width: 11vw !important; */
    padding: 0 !important;
    padding-top: .5rem !important;
        padding-bottom: .5rem !important;
    /* padding-top: .5rem !important; */
    /* padding-bottom: .5rem !important; */
    /* background-color: white; */
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    text-decoration: none;
    border-radius: .5rem;
}

@media (max-width: 440px) {
    .prompt-buttons-container {
       
                    justify-content: space-around !important;
    }
    .prompt-buttons-container button {
        width: 100px;
        font-size: 12px !important;
    }
        .prompt-buttons-container>button:first-child {
            width: 100px !important;
        }
}

.info-icon-container {
    display: flex;
    align-items: center;
    gap: .5rem;
    opacity: .8;
    width: 100%;
    margin-bottom: 5px;
}

.keep-designing-btn {
    width: 80% !important;
}


.clickable-wrapper {
    display: inline-block;
    position: relative;
    margin-right: 20px;
    background-color: white;
    /* padding: .3rem 1.5rem .3rem 1rem; */
    padding: .2rem;
    border-radius: 1rem; 
    margin: .2rem;
            /* Adjusted horizontal margin */
    /* Adjust as needed */
}

.remove-word {
    position: absolute;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* padding-bottom: 4px; */
    /* padding: 1px !important; */
    /* flex-direction: column; */
    /* top: 0px; */
    font-size: 20px !important;
    /* Adjust as needed */
    /* right: 0cqmax; */
    /* Adjust as needed */
    background-color: black;
    color: white;
    border-radius: 1rem;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    width: 24px;
    /* Adjust as needed */
    height: 20px;
    left: 0rem;
    top: -.3rem;
    /* Adjust as needed */
    text-align: center;
    line-height: 14px !important;
    /* Adjust as needed */
    cursor: pointer;
    font-size: 14px;
    transition: all ease-in-out .3s;
    /* Adjust as needed */

}

.remove-word {
    opacity: 0;
    transition: all ease-in-out .3s;
    /* Hide by default */
    cursor: pointer;
    /* top: 2rem; */
    /* right: 3rem; */
    /* position: absolute; */
    display: inline;
    font-size: 30px;
    z-index: 1000;
    margin-top: .2rem;
    /* Other styling for the 'x' icon */
}


.clickable-wrapper {
    display: inline;
    width: 100%;
  
    /* margin-top: 1rem; */
            /* margin-top: 1rem !important; */
}

.clickable-wrapper:hover  .remove-word {
    opacity: 1;
    visibility: visible;
    /* Other styles as needed */
}

.madlibs-container {
    font-size: 40px;
    font-family: oatmeal-pro-regular;
    display: inline;
    margin-bottom: .4rem;
}

.clickable {
    width: 100%;

}

.save-design-button {
    transition: .2s all ease-in-out;
    cursor: pointer;
}


.save-design-button:hover {
            transform: scale(1.1);
}







@media (max-height: 740px) {

    .preview-images-container,
    .preview-image-container {
        height: 300px;
        width: 300px;
    }

    .madlibs-container {
        font-size: 40px;
    }

    .prompt-content-container {
        max-height: 76.5vh;
    }

    .preview-image {
         max-height: 300px !important;
    }
}

@media (min-height: 740px) and (max-height: 750px) {

    .preview-images-container,
    .preview-image-container {
        /* Adjust width and padding-bottom to maintain aspect ratio */
        height: 330px;
        width: 330px;
        /* Keep square aspect ratio */
    }

    .madlibs-container {
        /* Adjust font size as needed */
        font-size: 40px;
    }

    .prompt-content-container {
        gap: .5rem;
        max-height: 77.5vh;
    }

        
}

@media (min-height: 750px) and (max-height: 800px) {

    .preview-images-container,
    .preview-image-container {
        /* Adjust width and padding-bottom to maintain aspect ratio */
        height: 350px;
            width: 350px;
        /* Keep square aspect ratio */
    }

    .madlibs-container {
        /* Adjust font size as needed */
        font-size: 40px;
    }

    .prompt-content-container{
        gap: .5rem;
        max-height: 76.8vh;
    }
}

@media (min-height: 800px) and (max-height: 850px)  {

    .preview-images-container,
    .preview-image-container {
        /* Adjust width and padding-bottom to maintain aspect ratio */
        height: 380px;
        width: 380px;
        /* Keep square aspect ratio */
    }

    .madlibs-container {
        /* Adjust font size as needed */
        font-size: 40px;
    }

    .prompt-content-container {
        gap: 1rem;
        max-height: 77.3vh;
    }
}

@media (min-height: 850px) and (max-height: 900px) {

    .preview-images-container,
    .preview-image-container {
        /* Adjust width and padding-bottom to maintain aspect ratio */
        height: 300px;
        width: 300px;
        /* Keep square aspect ratio */
    }

    .madlibs-container {
        /* Adjust font size as needed */
        font-size: 40px;
    }

    .prompt-content-container {
        gap: 1.5rem;
        max-height: 78.5vh;
    }
}

@media (min-height: 900px) {

    .preview-images-container,
    .preview-image-container {
        /* Adjust width and padding-bottom to maintain aspect ratio */
        height: 400px;
        width: 400px;
        /* Keep square aspect ratio */
    }

    .madlibs-container {
        /* Adjust font size as needed */
        font-size: 40px;
    }

    .prompt-content-container {
        gap: 3rem;
    }
}
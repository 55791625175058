.cart-container-outer {
    margin: 2rem 4rem 4rem 4rem;
    padding: 2rem;
    border-radius: 10px;
    background: rgba(255, 255, 255, .6);
    /* max-height: 70vh; */
    /* height: 75vh !important; */
    /* overflow-y: scroll; */
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.cart-container-outer-loading {
display: flex;
    justify-content: center;
    align-items: center;
    
}

.cart-table-row-1 {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.cart-table-row-1 thead, tr, tbody {
    width: 100%;
    display: flex;
    text-align: center;
   justify-content: space-around;
    justify-content: space-between;
    justify-content: space-around;
}

thead{
    font-size: 25px;
}


td {
    min-width: 30px;
    font-size: 22px;
}



.cart-info-container {
    display: flex;
    /* justify-content: space-between; */
    justify-content: space-around !important;
}



.cart-hoodie-canvas {
    width: 20vw;
    height: 10vw;
     background: rgba(255, 255, 255, .3);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    transition: all ease-in-out .2s;
}

.cart-hoodie-canvas:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.canvas-checkout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.canvas-column {
    display: flex;
    align-items: center;
}

.cart-loading-animation{
    display: flex;
    justify-content: center;
height: 83vh !important;

        background: rgba(255, 255, 255, .6);
    width: 100%;
        margin: 2rem 4rem 4rem 4rem;
    align-items: center;
    border-radius: 2rem;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}



.cart-image-1 {
    min-height: 169px;
    width: 50%;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}


.cart-image-2 {
width: 50%;
    min-height: 169px;
border-top-right-radius: 1rem;
border-bottom-right-radius: 1rem;
}

.line-item-container {
    margin-top: 3rem;
}

.size-select{
    width: 70px;
    height: 50px;
    background-color: none;
    border: none;
    border-radius: .5rem;
    /* margin-left: 3rem; */
    font-size: 20px;
    padding: .5rem;
    outline: none;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.quantity-button {
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    width: 40px;
    margin: 1rem;
    height: 40px;
    border: none;
    background-color: none;
        border-radius: .5rem;
            font-size: 20px;
    /* margin-left: 20px; */
}

.quantity-button-container {
    margin-left: 20px;
}
/* body {
    margin: 0;
    height: 100vh;
    background: linear-gradient(45deg, #2685e3, #85b2e3, #b4c8e3, white, #2685e3, #85b2e3, #b4c8e3, white);
    background-size: 200% 200%;
    animation: AuroraAnimation 20s infinite linear;
}

@keyframes AuroraAnimation {
    0%, 100% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
} */


@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-image-editor/styles/material.css";

body,
html {
  height: 100%;
  margin: 0;
  
}

body {
  background-color: white;
    /* overflow: hidden; */
}

html {
  /* overflow: hidden; */
}

#root,
.App {
  min-height: 100%;
}

.App {
  min-height: 100%;
  position: relative;
}

.App {
  display: flex;
  flex-direction: column;
}



#gradient-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

#gradient-canvas {
  position: absolute;
    position: fixed;
  z-index: -1;
  width: 100%;
  /* height: 100%; */
  --gradient-color-1: #1495ff;
  --gradient-color-2: #47bcff;
  --gradient-color-3: #e2d6ff;
  --gradient-color-4: #0011ff;
  /* --gradient-color-1: #000000; */
  /* --gradient-color-2:  #0011ff; */
  /* --gradient-color-3: #FF00FF; */
  /* --gradient-color-4: black; */
}




@font-face {
  font-family: oatmeal-pro-bold;
  src: url(../public/OatmealProTRIAL-Bold.otf);
}

@font-face {
  font-family: oatmeal-pro-regular;
  src: url(../public/OatmealProTRIAL-Regular.otf);
}

@font-face {
  font-family: oatmeal-pro-thin;
  src: url(../public/OatmealProTRIAL-Light.otf);
}

@font-face {
  font-family: act-of-creation;
  src: url(../public/ActOfCreation.otf);
}



.nav-container {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  flex: 2;
  color: white;
  font-family: oatmeal-pro-bold;
  /* position: absolute; */
  /* background-color: rgba(255, 255, 255, 0.4);; */
  padding: .2rem 0 0rem 0;
}

.nav-container > div {
  display: flex;
  gap: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.App {
  display: flex;
  flex-direction: column;
}

.nav-container span {
  margin-top: .5rem;
}

.nav-container a {
  font-size: 20px;
}

.act-of-creation-logo{
  transition: all ease-in-out .55s;
}

.act-of-creation-logo:hover {
  filter: invert(1);
} 

.cartIcon {
  position: absolute;
  top:14px;
  right: 135px;
  transition: all ease-in-out .55s;
}

.cartIcon:hover {
    filter: invert(1);
}

.nav-container a {
  margin: 0;
  padding: 0;
  width: 3px;
}

.drop-down-menu {
  background-color: rgba(20, 20, 20, .6) !important;
    backdrop-filter: blur(12px);
  z-index: 2000;
  position: absolute;
  /* top: 3.5rem; */
  right: 0;
  height: auto;
  padding: 2rem;
  width: 150px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  
}

.drop-down-menu li {
  list-style-type: none;
  font-size: 30px;
  font-family: oatmeal-pro-bold;
}

.drop-down-menu li a {
  background: none;
  font-size: 30px;
  font-family: oatmeal-pro-bold;
  color: white;
  text-align: left;
  justify-content: left;
  padding: 0 !important;
  margin: 0;
  transition: all .2s ease-in-out;
}

.drop-down-menu li a:hover {
  color: black;
}

.home-icon {
  width: 60px;
  border-radius: 50%;
  margin-left: 3rem;
}



.scrollable-container {
  position: fixed;
  top: 60vh;
  /* Adjust to appear below the phone */
  width: 100%;
  height: 50vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  /* z-index: 1000; */
  /* Optional: to create a background */
}

.scroll-item {
  margin: 20px;
  text-align: center;
}

.scroll-item img {
  max-width: 100px;
  max-height: 200px;
}

.live-hoodie-feed-canvas {
    height: 99vh;
    background-color: transparent;
}

.clock {
    font-family: oatmeal-pro-bold;
    font-size: 25px;
    background-color: rgba(255, 255, 255, 1) ;
    /* background-color: red; */
    position: absolute;
    z-index: 100;
    width: 20%;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
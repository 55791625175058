.image-editor-container {
    position: fixed;
    /* height: 60vw; */
    height: 90.6vh !important;
    width: 100vw;
    /* background-color: white; */
    z-index: 100;
    left: 0;
    padding-left: 2rem;
    padding-bottom:2.5rem;
    padding-right: 2rem;
    box-sizing: border-box;
    border-radius: 1rem;
    /* background-color: red; */
    /* opacity: .5; */
    top: 4rem;
    backdrop-filter: blur(5px);
    z-index: 1001;
}

.image-editor-sub-container {
    /* background-color: rgba(255, 255, 255, .8); */
    /* background-color: rgba(20, 20, 20, .96); */
    /* opacity: .8; */
    width: 100%;
    /* height: calc(100% - 2rem); */
    height: 89.6vh;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    gap: 2rem;
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Adjusted height */
}

.image-editor-sub-container-content{
    /* background-color: rgba(255, 255, 255, .8); */
        /* opacity: .8; */
        /* width: 100%; */
        /* height: calc(100% - 2rem); */
        height: 71.6vh;
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        gap: 0rem;
        display: flex;
        flex-direction: column;
        /* width: 60%; */
        /* background-color: red; */
        /* box-sizing: border-box !important; */
}

.selected-editor-image {
    height: 100%;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    border-radius: 2rem;
}

.canvas-container {
    width: 40vw;
    /* min-width: 50%; */
    /* padding-top: 40vw; */
    /* background-color: red; */
    /* This will make height equal to width */
    position: relative;
    min-width: 637px;
        /* or whatever minimum you prefer */
        min-height: 600px;
  
}

.image-canvas {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* width: 100%; */
    /* height: 90%; */
    /* border-radius: 1rem; */
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 1rem;
    /* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3); */
    /* background-color: blue; */
    
}

.image-editor-buttons-container{
    display: flex;
    flex-direction: column;
    /* margin-top: 5rem; */
    gap: 2rem;
    background-color: rgb(20, 20, 20);
    padding: 1rem;
    /* border: .5px solid white; */
    /* border-radius: 1rem; */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 1rem;
    
}

.image-editor-buttons-container button {
    background-color: black;
    color: white;
    padding: .5rem;
    border: none;
    border-radius: .5rem;
    font-size: 18px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: .5rem;
    align-items: center;
    transition: .2s ease-in-out all;
    flex-direction: column;
    width: 80px;
}

.image-editor-buttons-container button:hover {
    transform: scale(1.05);
}

.editor-icon {
    width: 20px;
    filter: invert(1);
}

.image-editor-help-container {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    gap: 2rem;
    width: 100px;
}

.image-editor-help-container  button {
    transition: .2s ease-in-out all;
    background-color: black;
    color: white;
    padding: .5rem;
    border: none;
    border-radius: .5rem;
    font-size: 18px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: .5rem;
    align-items: center;
}

.image-editor-help-container button:hover {
    transform: scale(1.05);
}

.help-content-container {
    width:20vw;
    border-radius: .5rem;
    background-color: #191919;
    height: 100%;
}

.image-editor-input-container {
    height: 50px;
    display: flex;
    gap: 1rem;
}

.generate-input {
    width: 40vw;
    border-radius: .5rem;
    font-size: 20px;
    padding-left: 1rem;
    font-family: oatmeal-pro-regular;
    border: none;
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.generate-input:focus {
    outline: none;
}

.generate-button{
    background-color: rgb(20, 20, 20);
    color: white;
    padding: .5rem;
    border: none;
    border-radius: 1rem;
    font-size: 18px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: .5rem;
    align-items: center;
    transition: .2s ease-in-out all;
    height: 100%;
}
/* 
.generate-button:hover{
    transform: scale(1.05);
} */

.image-editor-x-btn {
    /* position: absolute; */
    padding: 1px;
    margin-left: .5rem;
    left: 3rem;
    top: 1rem;
    cursor: pointer;
    transition: all ease-in-out .2s;
        filter: invert(1);
}

.image-editor-x-btn:hover {
    transform: scale(1.2);
}

.formatting-bar {
    width: 100%;
    background-color: rgb(20, 20, 20);
    display: flex;
    align-items: center;
    /* height: 40px; */
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;

}

.formatting-bar  {
    color: white;
    display: flex;
    justify-content: space-between;
}

.slider {
    width: 300px;
    height: 3px;
    border-radius: 1.3px;
    outline: none;
    -webkit-appearance: none;
    /* For Chrome and Safari */
    appearance: none;
    /* margin-bottom: .5rem; */
}

/* For Chrome */
::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    /* Adjust width as needed */
    height: 15px;
    /* Adjust height as needed */
    background: #fff;
    /* Thumb background color */
    cursor: pointer;
    border-radius: 10px;
    /* Adjust for rounded corners */
    /* border: 1px solid #000; */
    /* Optional: border for thumb */
}

/* For Firefox */
::-moz-range-thumb {
    width: 15px;
    /* Adjust width as needed */
    height: 15px;
    /* Adjust height as needed */
    background: #fff;
    /* Thumb background color */
    cursor: pointer;
    border-radius: 10px;
    /* Adjust for rounded corners */
    border: 1px solid #000;
    /* Optional: border for thumb */
}


/* ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
} */

.slider-container {
    display: flex;
    justify-content: right;
    gap: 3rem;
    width: 100%;
}

.editor-container {
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc),
            linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc);
        background-size: 20px 20px;
        background-position: 0 0, 10px 10px;
        background-color: white;
        border-bottom-right-radius: 1rem;
}

.clear-selection-btn{
    /* width: 110px; */
    margin: 1rem;
    font-family: oatmeal-pro-regular;
    background-color: black;
    border: none;
    color: white;
    padding: .4rem;
    border-radius: .5rem;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        transition: all ease-in-out .2s;
    /* height: 30px; */
}

.clear-selection-btn:hover {
    transform: scale(1.05);
}

.account-container-outer {
    background: rgba(255, 255, 255, .4);
    margin: 2rem 4rem 4rem 4rem;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}  


.account-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
    padding-left: 4rem;
    padding-right: 4rem;
    background: transparent;
    width: 100%;
    height: 74vh;
}

.account-container h1 {
    font-size: 40px;
    color: black !important;
}

.account-container h3 {
    color: black;
    font-size: 25px;
}

.account-profile-pic {
    width: 130px;
    border-radius: 50%;
    object-fit: cover;
}

.account-container h4 {
    margin-left: 2rem;
}

.reset-password-btn {
    border: none;
    height: 50px;
    background-color: black;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
        color: white;
        font-size: 20px;
        border-radius: .5rem;
        padding: 1rem;
        transition: all .3s ease-in-out;
        cursor: pointer;
        font-family: oatmeal-pro-regular;
}

.reset-password-btn:hover {
    background-color: white;
    color: black;
}



.generate-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.generate-profile-container input {
    height: 40px;
    width: 500px;
    padding: .5rem;
    outline: none;
    border: none;
    border-radius: .5rem;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.loader-container {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .8);
}

.account-info-container{
    display: flex;
    width: 100%;

}

.personal-info-container {
    flex: 1;
}

.profile-pics-container {
    /* flex: 1; */
    /* display: flex; */
    width: 100%;
    flex: 3;
}

.profile-pics-container img {
}

.users-profile-pics {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

}

.users-profile-pics img {
    width: 100px;
    border-radius: 50%;

}

.generate-btn {
    background: white;
    border: none;
    height: 50px;
    border-radius: .5rem;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all .5s ease-in-out;
    font-family: oatmeal-pro-regular;
}

.generate-btn:hover {
    background-color: black;
    color: white;
}
.user-designs-container {
    padding: 1rem;
    margin: 0 !important;
    overflow-y: scroll;
}

.user-design{
    width: 100%;
    max-width: 135px;
        height: auto;
        border-radius: .5rem;
        cursor: pointer;
        border: 2px solid rgba(0, 0, 0, 0);
        transition: all ease-in-out .1s;
}

.user-design:hover {
    border: 2px solid white;
}


@media (min-height: 750px) and (max-height: 800px) {
    .user-designs-container {

        max-height: 77vh;
    }
}

@media (min-height: 800px) and (max-height: 850px) {
    .user-designs-container {

        max-height: 78vh;
    }
}

@media (min-height: 850px) and (max-height: 900px) {
    .user-designs-container {

        max-height: 79vh;
    }
}

@media (min-height: 900px) {
    .user-designs-container {

        max-height: 77vh;
    }

}
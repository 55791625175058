.image-upload-container {
    padding: 2rem;
    max-height: 76vh;
        /* Set a max-height for your container */
        overflow-y: auto;
}

.uploaded-image-container {
    margin-top: 20px;
    border: 2px dashed rgb(83, 0, 255);
    border-radius: 1rem;
    display: flex;
    flex-wrap: wrap;
    /* Allow items to wrap within the container */
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 600px;
    /* Set a max-height for your container */
    overflow-y: auto;
    /* Enable scrolling when content overflows */
}

.uploaded-image {
    width: 100% !important;
    border-radius: 1rem;
}

.custom-upload-button {
    background-color: #2685e3;
    font-family: oatmeal-pro-regular;
    /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
   
}

.upload-image-input {
    /* Style adjustments if needed */
}


.slider-container {
    width: 100%;
    justify-content: center;
    
}

.zoom-slider {
    width: 100%;
}

.user-designs-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 2rem;
}

.user-design img {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 8px; 
}



@media (max-height: 740px) {
.image-upload-container {
        max-height: 71vh;
    }
  
}

@media (min-height: 740px) and (max-height: 750px) {

.image-upload-container {
        max-height: 72vh;
    }
}

@media (min-height: 750px) and (max-height: 800px) {
.image-upload-container {
        max-height: 73vh;
    }
}

@media (min-height: 800px) and (max-height: 850px) {
.image-upload-container {
        max-height: 74vh;
    }
}

@media (min-height: 850px) and (max-height: 900px) {
.image-upload-container {
        max-height: 75vh;
    }
}

@media (min-height: 900px) {

}
.design-portal-container {
    /* margin-top: 1vh; */
    display: flex;
    /* position: absolute; */
    z-index: 10;
    /* flex-direction: column; */
    /* width: 50px; */
    /* gap: 4rem; */
    /* top: 10rem; */
    /* left: 3rem; */
    /* padding: 0 2rem 2rem 2rem; */
    box-sizing: border-box;
    /* height: 92vh !important; */
    height: 100vh !important;
    gap: 0rem;
    overflow-y: hidden;
    font-family: oatmeal-pro-regular;
}

.design-portal-btn {
    height: 60px;
    width: 60px;
    cursor: pointer;
    filter: invert(1);
    transition: all .1s ease-in-out;
}

.design-portal-btn:hover, .lottery-btn:hover {
    transform: scale(1.1);
}

.lottery-btn {
    height: 65px;
    width: 65px;
    cursor: pointer;    
    transition: all .1s ease-in-out;
}

.checkout-btn-container {
    position: absolute;
    right: 3rem;
    cursor: pointer;
    z-index: 13;
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
}

.checkout-btn-container a {
    color: white;
    text-decoration: none;
    font-family: oatmeal-pro-bold;
    transition: .1s all ease-in-out;
    font-size: 25px;
    
}

.checkout-btn-container a:hover {
    color: white;
    text-decoration: none;
    font-family: oatmeal-pro-bold;
    transform: scale(1.1);
}

.genie-lamp-canvas {
    position: absolute;
    z-index: 12;
    bottom: 0;
    width: 100vw;
}

/* .design-portal-container {
    display: flex;

} */

.hoodie_canvas {
    /* height: 90%; */
    display: flex;
    flex: 2;
    flex-direction: row;
    /* align-items: center; */
    /* padding: 2rem; */
    box-sizing: border-box;
    gap: 1rem;
    /* background-color: blue; */
    background-color: rgba(255, 255, 255, .3);
    /* border-radius: 1rem; */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    border-right: 1px solid black;
}

.hoodie-canvas-left {
    flex: 3;
    /* align-items: start; */
    height: 100%;
}

.hoodie-scene {
    /* max-width: 300px; */
    height: 100%;
    /* background-image: linear-gradient(120deg, #16002b, #2d004f, #16002b); */
    background-image: white;
    /* margin-left: 4rem; */
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prompt-container {
    /* position: relative; */
    flex: .75;
    justify-content: start;
    z-index: 16;
    background-color: rgba(255, 255, 255, .3);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNCIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNCA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0yIDIuNUMyLjI3NzEgMi41IDIuNSAyLjI3NzEgMi41IDIgMi4yNzcxIDEuNSAyIDEuNSAxLjcyMjkgMS41IDEuNSAyIDEuNSAyLjI3NzEgMiAyLjUiIGZpbGw9IiMwMDAwMDAiIGZpbGwtb3BhY2l0eT0iMC4wNSIvPjwvc3ZnPg==");
    background-blend-mode: overlay; /* This will blend the noise with the background color */
    border-radius: 1rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    /* min-width: 430px; */
    position: absolute;
    right: 0rem;
    top: 0rem;
    /* width: 200px; */
}


.prompt-medium-options{
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.prompt-medium-options button {
    height: 50px;
    border-radius: 1rem;
    border: 1px solid grey;
    background-color: white;
    font-family: oatmeal-pro-regular;
    font-size: 20px;
    width: 100%;
    cursor: pointer;
}

.edit-button {
    position: absolute;
    right: 0;
    margin: .5rem;
    background-color: #5300FF;
    border-radius: .2rem;
    color: white;
    border: none;
    padding:.3rem;
    font-family: oatmeal-pro-regular;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: .1s ease-in-out all;
}

.edit-button:hover {
    transform: scale(1.1);
}

.product-details-button {
    position: absolute;
    margin-left: 20px !important;
    font-family: oatmeal-pro-regular;
    display: flex;
    align-items: center;
    gap: .5rem;
    text-decoration: none;
        border-bottom: 1px solid currentColor;
        padding-bottom: 2px;
        opacity: .7;

    z-index: 100;
    cursor: pointer;
}


.switch {
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px / 3 ;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: flex-start;
    border-radius: 50px;
    padding: 6px;
    cursor: pointer;
}

.switch[data-isOn="true"] {
    justify-content: flex-end;
}

.handle {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 40px;
}



.free-range-input-container{
    border-radius: 1rem;
position: absolute;
bottom: 9.1rem;
width: 40vw;
display: flex;
background-color: rgba(255, 255, 255, 0.3);
justify-content: right;
/* background-color: red; */
border-bottom-right-radius: .5rem;
    border-top-right-radius: .5rem;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    font-family: oatmeal-pro-bold;
    box-shadow:
            inset 0 0 30px #fff,
            0 0 20px #fff,
            0px 0 20px #0ff;

outline: 1px solid white;

}

.free-range-input {
    z-index: 1000;
    width: 40vw;
        border-radius: 1rem;
        font-size: 20px;
        padding-left: 1rem;
        border: none;
        font-family: oatmeal-pro-bold;
        /* position: absolute; */
        bottom: 1.1rem;
        /* top: 0; */
    background-color: rgba(255, 255, 255, 0.4);
    height: 55px;
        backdrop-filter: blur(10px);
        border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        
    
}

@media (max-width: 440px) {
    .free-range-input-container {
        min-width: 90vw;
       
    }

    .free-range-input {
        width: 80vw;
        font-size: 16px;
        height: 45px;
    }
}

.free-range-input:focus {
    outline: none;
}


.generate-image-free-range-btn {
    position: relative;
    /* Adjusted from display: absolute; */
    top: 0rem;
    /* Other properties */
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-bottom-right-radius: .5rem;
    border-top-right-radius: .5rem;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 17px;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    font-family: oatmeal-pro-bold;
    transition: all ease-in-out .3s;

}


.generate-image-free-range-btn:hover {
    position: relative;
    /* Adjusted from display: absolute; */
    top: 0rem;
    /* Other properties */
    background-color: #8C6CFF;
    
    backdrop-filter: blur(10px);
    border-bottom-right-radius: .5rem;
    border-top-right-radius: .5rem;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 17px;
    border-left: 1px solid #5300FF;
    font-family: oatmeal-pro-bold;
    color: white;
}

.instructions-container {
    position: fixed;
    z-index: 1001;
    display: flex;
    width: 96.3%;
    height: 90%;
    /* background-color: red; */
    justify-content: center;
        padding: 2rem;
        top: 0rem;
       

}

@media screen and (min-height: 751px) {
    .instructions-container {
        height: 80% !important;
       top: 4rem
    }
}

.instructions-container div {
    /* background-color: blue; */
    width: 55%;
    /* background-color: rgba(20, 20, 20, .3)  !important; */
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
        border-radius: 1rem;
        color: white;
            /* backdrop-filter: blur(10px); */

}

.carousel-container {
    width: 100% !important;
    height: 100% !important;
        background-color: rgba(20, 20, 20, .6)  !important;
                backdrop-filter: blur(12px);
    }


.carousel-item {
    width: 100% !important;
    height: 86%;
    border-radius: 1rem !important;
    display: fle !important;
    justify-content: center !important;
    box-shadow: none !important;
    /* background-color: red !important; */
    /* box-shadow: none; */
}

.get-started-btn:hover {
    transform: scale(1.05);
}

.arrow-container {
    background-color: transparent !important;
    border-radius: none !important;
}

.carousel-container video {
    width: 100%;
    max-width: 630px;
    border-radius: 1rem !important;
}




.tab-buttons {
    position: relative;
    width: 100%;
        display: flex;
        
        
        /* justify-content: space-around; */
    /* ... your styles for the container ... */
}

.tab-container {
    
    width: 100%;
    display: flex;
    /* justify-content: space-around; */
    background-color: rgba(255, 255, 255, .3);
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    padding-bottom: .3rem;
    /* margin-top: 30vh; */
}

.tab-container button {
    flex: 1;
    cursor: pointer;
    /* Each button will take up equal space */
    /* Add your additional button styles here */
}


.tab-buttons button {
    background: none;
    color: black;
    font-family: oatmeal-pro-regular;
    border: none;
    font-size: 25px;
    /* ... your styles for the button ... */
}

.tab-buttons button.active {
    /* styles for active button */
}

.underline-2 {
    position: absolute;
    bottom: 0;
    height: 2px;
    /* or the thickness you want */
    background-color: #5300FF;
    /* your desired purple color */
    /* ... other styles for the underline ... */
}



.prompt-button:hover {
transform: scale(1.05);
}



/* .prompt-panel-toggle {
    position: absolute;
    top: 2rem;
    right: 0rem;
    width: 50px;
    background-color: white;
    height: 100px;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.prompt-panel-toggle-mobile {
    position: absolute;
    top: 2rem;
    right: 0rem;
    width: 50px;
    background-color: white;
    height: 100px
}
 */

/* */


.prompt-container {
    border-radius: 1rem !important;
    /* border-bottom-right-radius: 1rem !important; */
    position: fixed;
    top: 0;
    right: 0;
    /* height: 100vh;
     */
     /* border-bottom-left-radius: 0rem; */
     /* border-bottom-right-radius: 0rem; */
    /* width: 400px; */
    /* background: white; */
    z-index: 1001;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.prompt-panel-toggle {
    position: absolute;
    top: 50%;
    left: -40px;
    transform: translateY(-50%);
    width: 40px;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    writing-mode: vertical-rl;
    text-orientation: mixed;
}

.hoodie_canvas {
    position: relative;
    width: 100%;
    height: 100vh;
    /* background: #f5f5f5; */
}